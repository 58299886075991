import React, { useRef, useEffect } from "react";
import { GeoJSON } from "react-leaflet";
import * as topojson from "topojson-client";

export default function TopoJSON(props) {
  const layerRef = useRef(null);
  const { data, ...otherProps } = props;

  function addData(layer, jsonData) {
    if (jsonData.type === "Topology") {
      for (let key in jsonData.objects) {
        let geojson = topojson.feature(jsonData, jsonData.objects[key]);
        layer.addData(geojson);
      }
    } else {
      layer.addData(jsonData);
    }
  }

  function onEachFeature(feature, layer) {
    if (feature.properties) {
      const { OBJECTID_1,Simbolo_U,Descripcion, Edad, UG_integra,Comentario, SHAPE_AREA,SHAPE_LEN } = feature.properties;
      layer.bindPopup(`<TABLE BORDER="1" CELLSPACING="1" WIDTH="300 COlOR="Green"">
      <TR><TD BGCOLOR="#70F989">OBJETIVED_1</TD> <TD>${OBJECTID_1}</TD></TR>
       <TR><TD BGCOLOR="#70F989">Simbolo_U</TD> <TD>${Simbolo_U}</TD></TR>
       <TR><TD BGCOLOR="#70F989">Descripcion</TD> <TD>${Descripcion}</TD>
       <TR><TD BGCOLOR="#70F989">Edad</TD> <TD>${Edad}</TD>
       <TR><TD BGCOLOR="#70F989">UG_integra</TD> <TD>${UG_integra}</TD>
       <TR><TD BGCOLOR="#70F989">Comentario</TD> <TD>${Comentario}</TD>
       <TR><TD BGCOLOR="#70F989">SHAPE_AREA</TD> <TD>${SHAPE_AREA}</TD>
       <TR><TD BGCOLOR="#70F989">SHAPE_LEN</TD> <TD>${SHAPE_LEN}</TD>
       </TABLE>`);
      //layer.bindPopup(`${OBJECTID_1},${Simbolo_U},${Descripcion}, ${Edad}, ${UG_integra},${Comentario}, ${SHAPE_AREA}, ${SHAPE_LEN}`);
     
    }
  }
// listo 
  useEffect(() => {
    const layer = layerRef.current;
    addData(layer, props.data);
  }, [props.data]);

  return (
    <GeoJSON ref={layerRef} {...otherProps} onEachFeature={onEachFeature} />
  );
}
