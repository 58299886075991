import React, { useEffect } from "react";
import "leaflet/dist/leaflet.css";
import { MapContainer, TileLayer, useMap } from "react-leaflet";
//import PrintControlDefault from 'react-leaflet-easyprint';
import L from "leaflet";
import "leaflet-easyprint";
import "./index.css";
import TopoJSON from "./mapa";
import topojson from "./database/mapa2.json";

const PrintMap = () => {
  const map = useMap();
  useEffect(() => {
    L.easyPrint({
      sizeModes: ["A4Portrait"],
      exportOnly: true
    }).addTo(map);
  }, []);

  return <></>;
};


export default function App() {
  return (
    <MapContainer
      center={[4.6232791897918215, -74.18949217359602]}
      zoom={5}
      style={{ height: "70vh" }}
    >
      <TileLayer
        attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
      />
      <TopoJSON data={topojson} />
      <PrintMap />
      
    </MapContainer>
    
  );
}
